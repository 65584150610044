import React from 'react'

export default function CrossIcon() {
  return (
    <svg viewBox="0 0 128 128" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g
        id="crossIcon"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <path
          d="M17.4357798,17.4357798 L111.56422,111.56422"
          id="Line"
          stroke="#ffffff"
          strokeWidth="4"
        />
        <path
          d="M111.56422,17.4357798 L17.4357798,111.56422"
          id="Line-2"
          stroke="#ffffff"
          strokeWidth="4"
        />
      </g>
    </svg>
  )
}
